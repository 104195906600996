import React from "react";
import {
  defaultStartAt,
  defaultParams,
  defaultVersion,
  addDays,
  segment,
  log,
} from "./utils";
import { JSONSchema6 } from "json-schema";
import { segmentSchema } from "./schemas";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { getEndorsement } from "./requests";

import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";

const Form = withTheme(MuiTheme);

const endorsementData = {
  endorsement: {
    start_at: addDays(defaultStartAt, 90).toJSON(),
    params: defaultParams,
  },
  version: defaultVersion,
  segments: [segment(defaultStartAt, 365)],
};

const endorsementSchema: JSONSchema6 = {
  definitions: {
    Segment: segmentSchema,
  },
  type: "object",
  properties: {
    endorsement: {
      type: "object",
      title: "Endorsement",
      properties: {
        start_at: {
          title: "Endorsement Time",
          description: "When to endorse",
          type: "string",
          format: "date-time",
        },
        params: {
          title: "Endorsement Params",
          description: "",
          type: "object",
          additionalProperties: {},
        },
      },
    },
    version: {
      type: "string",
      title: "Rater Version",
    },
    segments: {
      type: "array",
      title: "Policy Update segments",
      minItems: 1,
      items: {
        $ref: "#/definitions/Segment",
      },
    },
  },
};

const uiSchema = {
  "ui:options": {
    expandable: true,
  },
};

export const Endorsement = () => {
  let history = useHistory();

  return (
    <div>
      <h2> Endorsement </h2>
      <Form
        schema={endorsementSchema}
        formData={endorsementData}
        uiSchema={uiSchema}
        onChange={log("changed")}
        onError={log("errors")}
        onSubmit={async (data) => {
          console.log(data.formData);

          let res = await getEndorsement(data.formData);
          let response = res.data;

          console.log(response);
          history.push(`/endorsement/${response.payment_cents}`, {
            rows: response.rows,
          });
        }}
      >
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};
