import { JSONSchema6 } from "json-schema";
import { addDays, defaultVersion, defaultParams } from "./utils";

export const PolicyCancelledSchema: JSONSchema6 = {
  type: "object",
  properties: {
    at: {
      type: "string",
      format: "date-time",
    },
    policy: {
      type: "string",
      default: "ignored",
      description: "end time",
    },
  },
};

export const PolicyCreatedSchema: JSONSchema6 = {
  type: "object",
  properties: {
    effective_at: {
      title: "Start Time",
      description: "start time",
      type: "string",
      format: "date-time",
    },
    end_at: {
      title: "End Time",
      description: "end time",
      type: "string",
      format: "date-time",
    },
    params: {
      title: "Rater Inputs",
      description: "",
      type: "object",
      default: defaultParams,
      additionalProperties: {},
    },
    product: {
      title: "Product Name",
      description: "",
      type: "string",
      enum: ["BOP", "MPL", "CRIME"],
    },
    version: {
      title: "Rater Version",
      description: "",
      type: "string",
    },
  },
};

export const EventSchema: JSONSchema6 = {
  type: "object",
  properties: {
    type: {
      title: "EventType",
      description: "",
      type: "string",
      enum: ["policy_created", "policy_cancelled", "endorsement_created"],
    },
    params: {
      description: "Event params",
      type: "object",
      //"default": defaultParams,
      oneOf: [
        //PolicyCreatedSchema,
        PolicyCancelledSchema,
      ],
      additionalProperties: {},
    },
  },
};

export const EventForm: JSONSchema6 = {
  type: "object",
  properties: {
    items: {
      type: "array",
      items: {
        type: "object",
        oneOf: [EventSchema],
      },
    },
  },
};

export enum EventType {
  PolicyCreated = "policy_created",
  PolicyCancelled = "policy_cancelled",
  EndorsementCreated = "endorsement_created",
  PolicyReinstated = "policy_reinstated"
}

export enum Product {
  BOP = "BOP",
  MPL = "MPL",
  Crime = "CRIME",
}

export type EndorsementCreated = {
  at: Date;
  params: object;
  policy_id: string;
};

export type PolicyCancelled = {
  at: Date;
  id: string;
  policy_id: string;
};

export type PolicyCreated = {
  effective_at: Date;
  end_at: Date;
  id: string;
  params: object;
  product: Product;
  version: string;
  metadata: object;
};
export type PolicyReinstated = {
  effective_at: Date,
  policy_id: string,
  id: string,
};

export type Event =
  | { type: EventType.PolicyCreated; params: PolicyCreated }
  | { type: EventType.PolicyCancelled; params: PolicyCancelled }
  | { type: EventType.EndorsementCreated; params: EndorsementCreated }
  | { type: EventType.PolicyReinstated; params: PolicyReinstated };

export const startAt = new Date(2020, 0, 1);

let policyId = "abc-123";
let endAt = addDays(startAt, 366)
export const initialEvent: Event = {
  type: EventType.PolicyCreated,
  params: {
    effective_at: startAt,
    id: policyId,
    end_at: endAt,
    params: defaultParams,
    product: Product.BOP,
    version: defaultVersion,
    metadata: {},
  },
};

export const endorsementOne: Event = {
  type: EventType.EndorsementCreated,
  params: {
    at: addDays(startAt, 50),
    params: {
      hnoa_accepted: true,
    },
    policy_id: policyId,
  },
};

let endorsementTwo: Event = {
  type: EventType.EndorsementCreated,
  params: {
    at: addDays(startAt, 100),
    params: {
      emp_benefits_accepted: true,
    },
    policy_id: policyId,
  },
};

let cancelEventID = "cancel_id";
let policyCancelled: Event = {
  type: EventType.PolicyCancelled,
  params: {
    id: cancelEventID,
    at: addDays(startAt, 200),
    policy_id: policyId,
  },
};

let policyReinstated: Event = {
  type: EventType.PolicyReinstated,
  params: {
    effective_at: addDays(startAt, 300),
    id: "reinstated_id",
    policy_id: policyId,
  },
}
export const ExampleEventData: Array<Event> = [
  initialEvent,
  endorsementOne,
  endorsementTwo,
  policyCancelled,
  policyReinstated,
];
