import React, { useState } from "react";
import { getProjections } from "./requests";
import { JSONSchema6 } from "json-schema";
import { SegmentTable } from "./SegmentTable";
import { withTheme } from "react-jsonschema-form";
import { Button } from "@material-ui/core";
import { Theme as MuiTheme } from "rjsf-material-ui";
import { EventType, ExampleEventData } from "./events";

const Form = withTheme(MuiTheme);

const EventView = (props: any) => {
  let { event } = props;
  return (
    <div>
      <h3>{event.type}</h3>
      <pre>{JSON.stringify(event.params)}</pre>
    </div>
  );
};

const EventsForm = (props: {
  formData: { events: Array<any> };
  onSubmit: any;
}) => {
  let { formData, onSubmit } = props;
  let eventFormSchema: JSONSchema6 = {
    definitions: {
      Event: {
        type: "object",
        properties: {
          type: {
            type: "string",
            default: EventType.PolicyCreated,
            //@ts-ignore
            enum: Object.keys(EventType).map((k) => EventType[k]),
          },
          params: {
            type: "string",
          },
        },
      },
    },
    type: "object",
    properties: {
      events: {
        type: "array",
        items: {
          $ref: "#/definitions/Event",
        },
      },
    },
  };

  const uiSchema = {
    events: {
      items: {
        params: {
          "ui:widget": "textarea",
          "ui:options": {
            rows: 15,
          },
        },
      },
      "ui:options": {
        orderable: false,
      },
    },
  };
  return (
    <Form
      schema={eventFormSchema}
      formData={formData}
      uiSchema={uiSchema}
      onSubmit={onSubmit}
    >
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

let defaultFormData = {
  events: ExampleEventData.map((e) => {
    return {
      type: e.type,
      params: JSON.stringify(e.params, null, 2),
    };
  }),
};

export const EventBuilder = (props: any) => {
  const [state, setState] = useState({
    projections: [],
    events: [],
    error: null,
  });

  let { events, projections, error } = state;

  return (
    <div>
      <h1>EventBuilder</h1>
      {error ? <p>{JSON.stringify(error)}</p> : null}
      <h3> Events</h3>
      <div>
        {projections.map((projection: Array<any>, index) => {
          let foo = projection; //.map(fauxPres)
          let event = events[index];
          console.log("event");
          //console.log(event)
          return (
            <div key={index}>
              <EventView event={event} />
              <SegmentTable rows={[foo]} />
            </div>
          );
        })}
        <EventsForm
          formData={defaultFormData}
          onSubmit={(data: any) => {
            setState({ ...state, projections: [] });

            let jsonParsedFormData = data.formData.events.map((e: any) => {
              let r = { ...e, params: JSON.parse(e.params) };
              return r;
            });

            const fetchData = async (reqBody: any) => {
              try {
                let response = await getProjections(reqBody);
                let data = response.data;
                //let newd = {events: data.events}
                console.log(data.projections);
                setState({
                  ...state,
                  events: jsonParsedFormData,
                  projections: data.projections,
                  error: null,
                });
              } catch (err) {
                setState({ ...state, error: err.response });
              }
            };
            console.log(jsonParsedFormData);
            fetchData({ events: jsonParsedFormData }).then(() =>
              window.scrollTo(0, 0)
            );
          }}
        />
      </div>
    </div>
  );
};
