import React, { useState, useEffect, useCallback } from "react";
import { getProjection } from "./requests";
import { JSONSchema6 } from "json-schema";
import { SegmentTable, SegmentRow } from "./SegmentTable";
import { withTheme } from "react-jsonschema-form";
import { Button } from "@material-ui/core";
import { startAt } from "./events";
import { addDays } from "./utils";
import { Theme as MuiTheme } from "rjsf-material-ui";
import { ExampleEventData } from "./events";

const Form = withTheme(MuiTheme);

export const SnapshotConfig = (props: any) => {
  let { pres, paramDiff } = props;

  let style = { flex: `${pres.percentage * 100}%` };
  let className = 'policy-config';

  let keys = Object.keys(paramDiff);
  return (
    <div className={className} style={style}>
      <div className="details">
        {keys.map((k) => {
          // @ts-ignore
          let v = paramDiff[k]?.toString();
          return (
            <p key={k}>
              {k}: {v}
            </p>
          );
        })}
      </div>
    </div>
  );
};

const SnapshotView = (props: any) => {
  let { relevant_projection, at } = props;
  return (
    <div className="snapshot">
      <h3>Policy Snapshot at {at}</h3>
      <div>
        <h4>Config</h4>
        <div>
          <SnapshotConfig
            pres={relevant_projection[relevant_projection.length - 1]}
            paramDiff={relevant_projection[relevant_projection.length - 1].segment.inputs}
          />
        </div>
        <h4>Segments</h4>
        <div className="table">
          {
            // @ts-ignore
            [relevant_projection].map((segments, index) => {
              return <SegmentRow key={index} segments={segments} />;
            })
          }
        </div>
      </div>
    </div>
  )
}
const EventView = (props: any) => {
  let { event } = props;
  return (
    <div>
      <h3>{event.type}</h3>
      <pre>{JSON.stringify(event.params)}</pre>
    </div>
  );
};

const SnapshotForm = (props: { formData: { hasEventsFromUrl: boolean, events?: string }; onSubmit: any }) => {
  let { formData, onSubmit } = props;

  let events_format = formData.hasEventsFromUrl ? "hidden" : "textarea"

  let snapshotFormSchema: JSONSchema6 = {
    type: "object",
    properties: {
      at: {
        title: "View Snapshot At",
        description: "date of snapshot",
        type: "string",
        format: "date",
      },
      events: {
        type: "string",
      },
    },
  };

  const uiSchema = {
    events: {
      "ui:widget": events_format,
      "ui:options": {
        rows: 15,
      },
    },
  };
  return (
    <Form
      schema={snapshotFormSchema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={onSubmit}
    >
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

const to_iso = (date: Date) => date.toISOString().slice(0, 10);


let defaultFormData = {
  at: to_iso(addDays(startAt, 300)),
  events: JSON.stringify(ExampleEventData, null, 2),
  hasEventsFromUrl: false,
};

const getUrlEvents = (eventParams: any) => {
  let urlEvents = []
  if (eventParams != null && eventParams !== "") {
    try {
      urlEvents = JSON.parse(eventParams)
    } catch {
      console.warn(`Unable to parse json ${eventParams}`)
    }
  }
  return urlEvents
}

export const SnapshotViewer = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let eventParams = params.get('events');
  let at = params.get('at');

  let urlEvents = getUrlEvents(eventParams)

  let hasUrlEvents = (urlEvents.length > 0)

  const [state, setState] = useState({
    projection: [],
    events: urlEvents,
    formData: {
      at: at || defaultFormData.at,
      events: JSON.stringify(urlEvents) || defaultFormData.events,
      hasEventsFromUrl: hasUrlEvents,
    },
    error: null,
  });

  let { formData, events, projection, error } = state;

  const fetchData = useCallback(async (reqBody: any) => {
    try {
      let response = await getProjection(reqBody);
      let data = response.data;
      //@ts-ignore
      setState({
        ...state,
        formData: {
          events: formData.events,
          at: reqBody.at || at,
          hasEventsFromUrl: hasUrlEvents,
        },
        events: reqBody.events,
        projection: data.projection,
        error: null,
      });
    } catch (err) {
      setState({ ...state, error: err.response });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, state]);

  var lastCost = 0;


  useEffect(() => {
    if (hasUrlEvents) {
      //@ts-ignore
      fetchData({ events: urlEvents })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {hasUrlEvents ? "" : <h1>SnapshotViewer</h1>}
      {error ? <p>{JSON.stringify(error)}</p> : null}

      <SnapshotForm
        formData={formData}
        onSubmit={(data: any) => {
          setState({
            ...state,
            projection: [],
            formData: {
              at: data.formData.at,
              events: formData.events,
              hasEventsFromUrl: formData.hasEventsFromUrl,
            }
          });
          var events = JSON.parse(formData.events);

          const req = {
            ...data.formData,
            events,
          };
          fetchData(req).then(() => window.scrollTo(0, 0));
        }}
      />

      <div>
        {projection.length > 0 &&
          <div>
            <div>
              <SnapshotView
                relevant_projection={projection[projection.length - 1]}
                at={formData.at}
              />
            </div>
            <div>
              <h3>Event History</h3>
              {projection.map((projection: Array<any>, index) => {
                //@ts-ignore
                let cost = projection
                  .map((box) => (box.replaced ? 0 : box.amount_cents))
                  .reduce((a, b) => a + b);
                let diff = cost - lastCost;
                lastCost = cost;
                let event = events[index];
                return (
                  <div key={index}>
                    <EventView event={event} />
                    <div>
                      <p>Policy price ${cost / 100}</p>
                      <p>Price of event ${diff / 100}</p>
                    </div>
                    <SegmentTable rows={[projection]} />
                  </div>
                );
              })}</div>
          </div>
        }
      </div>
    </div>
  );
};
