import moment from "moment";
import { Segment, PresentableSegment } from "./types";

export const fauxPres = (segment: Segment): PresentableSegment => {
  return {
    segment: segment,
    amount_cents: 0,
    percentage: 100,
    replaced: false,
    metadata: {},
  };
};

export const addDays = (date: Date, days: number): Date => {
  return moment(date).add(days, "days").toDate();
};

export const defaultVersion = "UT-BOP-v10";
export const defaultParams = {
  state: "UT",
  city: "Salt Lake City",
  zip_code: "84044",
  territory_code: null,
  technology: "Software",
  customer: "Consumer",
  vertical: "Financial Technology",
  niche: "Insurance Tech - Property & Casualty",
  ntm_revenue: 20000000,
  num_employees: 25,
  num_locations: 1,
  hardware_manufacturer: false,
  schedule_classification: 0,
  payment_frequency: "annual",
  gl_offered: true,
  gl_accepted: true,
  gl_occ_limit: 1000000,
  gl_agg_limit: 2000000,
  gl_pco_limit: 2000000,
  gl_deductible: 1000,
  damage_rented_premise: 100000,
  pai_exclusion: false,
  pco_exclusion: false,
  hnoa_offered: true,
  hnoa_accepted: false,
  hnoa_limit: 1000000,
  bii_offered: true,
  bii_accepted: true,
  bii_limit: 100000,
  bpp_offered: true,
  bpp_accepted: true,
  bpp_limit: 100000,
  bpp_deductible: 1000,
  off_prem_bpp_limit: 15000,
  emp_benefits_offered: true,
  emp_benefits_accepted: false,
  emp_benefits_agg_limit: 2000000,
  emp_benefits_emp_limit: 1000000,
  tria_offered: true,
  tria_accepted: true,
  additional_insured: false,
  county: "",
  endorsement_form: false,
  incubator_program: false,
  partnership_disc: "None",
};
export const segment = (start_at: Date, days: number): Segment => {
  let end_at = addDays(start_at, days);

  return {
    start_at: start_at.toJSON(),
    end_at: end_at.toJSON(),
    inputs: defaultParams,
    version: defaultVersion,
  };
};

export const duration = (segment: Segment): number => {
  let end_at = new Date(segment.end_at).getTime();
  let start_at = new Date(segment.start_at).getTime();
  return end_at - start_at;
};

export const log = (type: any) => console.log.bind(console, type);

export const defaultStartAt = new Date(2020, 0, 1);
