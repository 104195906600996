import React from "react";
import { PresentableSegment } from "./types";
import moment from "moment";
import { pickBy} from "lodash";

const toMoney = (v:any) => `$${v / 100}`;

const annualizedSegmentAmount = (pres: PresentableSegment) => {
  //@ts-ignore
  let amountCents = pres.metadata?.annual_premium_cents || 0
  return toMoney(amountCents);
}

const requiredForms = (pres: PresentableSegment) => {
  //@ts-ignore
  let forms = pres.metadata?.rater_result?.forms || []

  return Object.keys(pickBy(forms))
}
const segment_coverage_premiums = (pres: PresentableSegment) => {
  //@ts-ignore
  return pres.metadata?.coverage_premiums || {}
}

const annual_coverage_premiums = (pres: PresentableSegment) => {
  //@ts-ignore
  return pres.metadata?.rater_result?.premiums || {}
}

let objectToTable = (obj: object, valueTransform?: any) => {
  let foo = (k:any) => {
    //@ts-ignore
    let v = obj[k]

    let t = valueTransform || ((v:any) => v);
    let newV = String(t(v))
    return tableRow(k, newV)
  }
  return (
    <table>
      <tbody>
      {Object.keys(obj).map(foo)}
      </tbody>
    </table>
  );
}

const tableRow = (k: any, v: any) => {
  return (
    <tr key={k}><td>{k}</td><td>{v}</td></tr>
  );
}
const SegmentCell = (props: {
  pres: PresentableSegment;
  paramDiff: object;
}) => {
  let { pres, paramDiff } = props;
  let { segment } = pres;

  let style = { flex: `${pres.percentage * 100}%` };
  var start = moment(segment.start_at); //todays date
  var date = start.format("L");
  let className = `policy-update-span ${pres.replaced ? "replaced" : ""}`;

  let segment_coverages = segment_coverage_premiums(pres);
  let annual_coverages = annual_coverage_premiums(pres);

  return (
    <div className={className} style={style}>
      <div className="details">
        <details>
          <summary>
            <div className="leading">
              <p>
                <small>Date</small><br />
                <b>{date}</b>
              </p>
              <p>
                <small>Amount</small><br />
                <b>{toMoney(pres.amount_cents)}</b>
              </p>
            </div>
          </summary>
          <details>
            <summary>annual premiums</summary>
            <table>
              <tbody>
                <tr>
                  <td><b>Annual Premium</b></td>
                  <td><b>{annualizedSegmentAmount(pres)}</b></td>
                </tr>
              </tbody>
            </table>
            { objectToTable(annual_coverages, (v: any) => `$${v}`) }
          </details>
          <details open>
            <summary>premiums</summary>
            { objectToTable(segment_coverages, toMoney) }
          </details>
          <details>
            <summary>
              forms
            </summary>
            <ul>
              {requiredForms(pres).map((form) => (<li key={form}>{form}</li>)) }
            </ul>
          </details>
          <details>
            <summary>
              params
            </summary>
            { objectToTable(paramDiff) }
          </details>
        </details>
      </div>
    </div>
  );
};

export const SegmentRow = (props: { segments: Array<PresentableSegment> }) => {
  let { segments } = props;

  return (
    <div className="timeline">
      {segments.map((pres, index) => {
        return (
          <SegmentCell
            key={index}
            pres={pres}
            paramDiff={pres.segment.inputs}
          />
        );
      })}
    </div>
  );
};

export const SegmentTable = (props: {
  rows: Array<Array<PresentableSegment>>;
}) => {
  let { rows } = props;

  return (
    <div className="table">
      {rows.map((segments, index) => {
        return <SegmentRow key={index} segments={segments} />;
      })}
    </div>
  );
};
