import React from "react";
import { SegmentTable } from "./SegmentTable";
import { addDays, segment, fauxPres } from "./utils";

export const SegmentView = (props: any) => {
  let start_at = new Date();
  let days = 100;
  let endorseDate = addDays(start_at, days);

  let beforeEndorsementRow = [segment(start_at, 365)].map(fauxPres);

  let afterEndorsementRow = [
    segment(start_at, days),
    segment(endorseDate, 265)
  ].map(fauxPres);

  let rows = [beforeEndorsementRow, afterEndorsementRow];

  return (
    <div>
      <h1>SegmentView</h1>
      <SegmentTable rows={rows} />
    </div>
  );
};
