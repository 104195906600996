import React from "react";
import { Container } from "@material-ui/core";
import "./App.css";
import { SegmentView } from "./Segments";
import { EventBuilder } from "./EventBuilder";
import { EventViewer } from "./EventViewer";
import { SnapshotViewer } from "./SnapshotViewer";
import { Endorsement } from "./Endorsement";
import { ERPResult, ERP } from "./ERP";
import { Header } from "./Header";
import CancellationEvents from "./CancellationEvents";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function SegmentRoutes() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let embedded = params.get('embedded') === "true";
  let width = embedded ? false : "md"

  return (
    <Router>
      <div>
        {embedded ? "" :
          <Header />
        }
        {/* 
        // @ts-ignore */}
        <Container maxWidth={width} disableGutters={embedded}>
          <Switch>
            <Route path="/endorsement/:premium" component={ERPResult} />
            <Route path="/segments" component={SegmentView} />
            <Route path="/endorsement">
              <Endorsement />
            </Route>
            <Route path="/erp/:premium" component={ERPResult} />
            <Route path="/erp">
              <ERP />
            </Route>
            <Route
              path="/cancellation_preview"
              component={CancellationEvents}
            />
            <Route path="/eventbuilder" component={EventBuilder} />
            <Route path="/eventviewer" component={EventViewer} />
            <Route path="/snapshotviewer" component={SnapshotViewer} />
          </Switch>
        </Container>
      </div>
    </Router>
  );
}
