import React from "react";
import { getERP } from "./requests";
import { useHistory } from "react-router-dom";
import { defaultStartAt, defaultVersion, log, segment } from "./utils";
import { JSONSchema6 } from "json-schema";
import { segmentSchema } from "./schemas";
import { SegmentTable } from "./SegmentTable";
import { Button } from "@material-ui/core";
import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";

const Form = withTheme(MuiTheme);

const erpSchema: JSONSchema6 = {
  definitions: {
    Segment: segmentSchema,
  },
  type: "object",
  properties: {
    version: {
      type: "string",
      title: "Rater Version",
    },
    years: {
      title: "Years of Coverage",
      description: "How many years do they need",
      type: "number",
    },
    segments: {
      type: "array",
      title: "Policy Update segments",
      minItems: 1,
      items: {
        $ref: "#/definitions/Segment",
      },
    },
  },
};

const erpData = {
  version: defaultVersion,
  segments: [segment(defaultStartAt, 365)],
  years: 2,
};

const uiSchema = {
  "ui:options": {
    expandable: true,
  },
};

export const ERP = () => {
  let history = useHistory();

  return (
    <div>
      <h2> Extended Reporting Period </h2>
      <Form
        schema={erpSchema}
        formData={erpData}
        uiSchema={uiSchema}
        onChange={log("changed")}
        onError={log("errors")}
        onSubmit={async (data) => {
          console.log(data.formData);

          let res = await getERP(data.formData);
          let response = res.data;

          console.log(response);
          history.push(`/erp/${response.premium_cents}`, {
            rows: response.rows,
          });
        }}
      >
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export const ERPResult = (props: any) => {
  const { match, location } = props;
  let { premium } = match.params;
  let premiumCents = premium / 100.0;

  const state = location.state;

  console.log(state.rows);
  let rows = state.rows;
  console.log(rows);

  return (
    <div>
      <h1>Premium ${premiumCents}</h1>
      <SegmentTable rows={rows} />
    </div>
  );
};
