import axios from "axios";

const buildURL = (path: string): string => {
  let baseUrl = process.env.REACT_APP_POLICY_MANAGER_API;
  return `${baseUrl}/${path}`;
};

export const getERP = (data: any): Promise<any> => {
  const url = "premium/extended_reporting_period";

  return axios.post(buildURL(url), data);
};
export const getEndorsement = (data: any): Promise<any> => {
  const url = "premium/endorsement";
  return axios.post(buildURL(url), data);
};

type ProjectionsRequest = {
  events: Array<any>;
};

export const getProjections = (data: ProjectionsRequest): Promise<any> => {
  const url = "premium/projections";
  return axios.get(buildURL(url), {
    params: data
  });
};

export const getProjection = (data: ProjectionsRequest): Promise<any> => {
  const url = "premium/projection";
  var a = axios.get(buildURL(url), {
    params: data
  });
  return a
};

export const getCancellationPreview = (data: any): Promise<any> => {
  const url = "premium/cancellation";
  return axios.get(buildURL(url), data);
};
