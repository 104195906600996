import React, { useState } from "react";
import { getCancellationPreview } from "./requests";
import { JSONSchema6 } from "json-schema";
import { withTheme } from "react-jsonschema-form";
import { Button } from "@material-ui/core";
import { Theme as MuiTheme } from "rjsf-material-ui";
import { initialEvent, endorsementOne, startAt } from "./events";
import { addDays } from "./utils";
import { SegmentTable } from "./SegmentTable";

const Form = withTheme(MuiTheme);

var i_event = initialEvent;
// @ts-ignore
i_event.params.metadata = { socotra_policy_locator: "100054603" };

let defaultEvents = [i_event, endorsementOne];

const EventsForm = (props: { formData: { events: string }; onSubmit: any }) => {
  let { formData, onSubmit } = props;
  let eventFormSchema: JSONSchema6 = {
    type: "object",
    properties: {
      cancel_at: {
        title: "Cancellation Time",
        description: "When the policy should be ended",
        type: "string",
        format: "date-time",
      },
      events: {
        type: "string",
      },
    },
  };

  const uiSchema = {
    events: {
      "ui:widget": "textarea",
      "ui:options": {
        rows: 15,
      },
    },
  };

  return (
    <Form
      schema={eventFormSchema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={onSubmit}
    >
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

let defaultFormData = {
  cancel_at: addDays(startAt, 300).toString(),
  events: JSON.stringify(defaultEvents, null, 2),
};

const CancellationEvents = (props: any) => {
  const [state, setState] = useState({
    formData: defaultFormData,
    error: null,
    result: null,
  });

  let { formData, error, result } = state;
  var rows = [];

  if (result != null) {
    try {
      //@ts-ignore
      rows = JSON.parse(result).rows;
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div>
      <h1>Preview Cancellation Price</h1>
      {error ? <p>{JSON.stringify(error)}</p> : null}
      <h3>Events</h3>
      <div>
        <details>{result ? result : ""}</details>
      </div>
      <SegmentTable rows={rows} />
      <div>
        <EventsForm
          formData={formData}
          onSubmit={(data: any) => {
            const fetchData = async (reqBody: any) => {
              try {
                let response = await getCancellationPreview(reqBody);
                let data = response.data;
                console.log(data.projections);
                //@ts-ignore
                setState({
                  ...state,
                  formData: formData,
                  error: null,
                  result: JSON.stringify(data),
                });
              } catch (err) {
                setState({ ...state, error: err.response });
              }
            };

            //@ts-ignore
            var events = JSON.parse(data.formData.events);
            const req = {
              ...data.formData,
              events,
            };
            console.log(req);

            fetchData(req).then(() => window.scrollTo(0, 0));
          }}
        />
      </div>
    </div>
  );
};
export default CancellationEvents;
