import React from "react";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <nav>
      <ul>
        <li>
          {" "}
          <Link to="/cancellation_preview">Cancellation</Link>{" "}
        </li>
        <li>
          {" "}
          <Link to="/endorsement">Endorsement</Link>{" "}
        </li>
        <li>
          {" "}
          <Link to="/erp">ERP</Link>{" "}
        </li>
        <li>
          {" "}
          <Link to="/segments">Segments</Link>{" "}
        </li>
        <li>
          {" "}
          <Link to="/eventbuilder">EventBuilder</Link>{" "}
        </li>
        <li>
          {" "}
          <Link to="/eventviewer">EventViewer</Link>{" "}
        </li>
        <li>
          {" "}
          <Link to="/snapshotviewer">SnapshotViewer</Link>{" "}
        </li>
      </ul>
    </nav>
  );
};
