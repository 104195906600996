import React, { useState, useEffect, useCallback } from "react";
import { getProjections } from "./requests";
import { JSONSchema6 } from "json-schema";
import { SegmentTable } from "./SegmentTable";
import { withTheme } from "react-jsonschema-form";
import { Button } from "@material-ui/core";
import { Theme as MuiTheme } from "rjsf-material-ui";
import { ExampleEventData } from "./events";

const Form = withTheme(MuiTheme);

const EventView = (props: any) => {
  let { event } = props;
  return (
    <div>
      <h3>{event.type}</h3>
      <pre>{JSON.stringify(event.params)}</pre>
    </div>
  );
};

const EventsForm = (props: { formData: { events: string }; onSubmit: any }) => {
  let { formData, onSubmit } = props;

  let eventFormSchema: JSONSchema6 = {
    type: "object",
    properties: {
      events: {
        type: "string",
      },
    },
  };

  const uiSchema = {
    events: {
      "ui:widget": "textarea",
      "ui:options": {
        rows: 15,
      },
    },
  };
  return (
    <Form
      schema={eventFormSchema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={onSubmit}
    >
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

let defaultFormData = {
  events: JSON.stringify(ExampleEventData, null, 2),
};

export const EventViewer = (props: any) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let eventParams = params.get('events');
  console.log(params);
  //@ts-ignore
  var urlEvents = []
  if(eventParams != null && eventParams !== "") {
    try {
      urlEvents = JSON.parse(eventParams)
    } catch {
      console.warn(`Unable to parse json ${eventParams}`)
    }
    console.log(urlEvents);
  }

  const [state, setState] = useState({
    projections: [],
    events: urlEvents,
    formData: defaultFormData,
    error: null,
  });

  let { formData, events, projections, error } = state;

  const fetchData = useCallback(async (reqBody: {events: Array<any>}) => {
    try {
      let response = await getProjections(reqBody);
      let data = response.data;
      console.log(data.projections);
      //@ts-ignore
      setState({
        ...state,
        formData: { events: JSON.stringify(events, null, 2) },
        events: reqBody.events,
        projections: data.projections,
        error: null,
      });
    } catch (err) {
      setState({ ...state, error: err.response });
    }
  }, [events, state]);


  let hasUrlEvents = (urlEvents.length > 0)
  var lastCost = 0;

  useEffect(() => {
    if(hasUrlEvents) {
      //@ts-ignore
      fetchData({events: urlEvents})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div>
      {  hasUrlEvents ? "" : <h1>EventViewer</h1> }
      {error ? <p>{JSON.stringify(error)}</p> : null}
      {  hasUrlEvents ? "" : <h3> Events</h3>}
      <div>
        {projections.map((projection: Array<any>, index) => {
          //@ts-ignore
          let cost = projection
            .map((box) => (box.replaced ? 0 : box.amount_cents))
            .reduce((a, b) => a + b);
          let diff = cost - lastCost;
          lastCost = cost;
          console.log(cost);
          let event = events[index];
          return (
            <div key={index}>
              <EventView event={event} />
              <div>
                <p>Policy price ${cost / 100}</p>
                <p>Price of event ${diff / 100}</p>
              </div>
              <SegmentTable rows={[projection]} />
            </div>
          );
        })}
        { hasUrlEvents ? "" : <EventsForm
          formData={formData}
          onSubmit={(data: any) => {
            setState({ ...state, projections: [] });
            //@ts-ignore
            console.log(data.formData.events);
            var events = JSON.parse(data.formData.events);
            console.log(events);
            fetchData({ events: events }).then(() => window.scrollTo(0, 0));
          }}
        /> }
      </div>
    </div>
  );
};
