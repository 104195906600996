import { JSONSchema6 } from "json-schema";

export const segmentSchema: JSONSchema6 = {
  type: "object",
  properties: {
    start_at: {
      title: "Start Time",
      description: "start time",
      type: "string",
      format: "date-time"
    },
    end_at: {
      title: "End Time",
      description: "end time",
      type: "string",
      format: "date-time"
    },
    inputs: {
      title: "Rater Inputs",
      description: "",
      type: "object",
      additionalProperties: {}
    }
  }
};
